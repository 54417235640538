import { Children, cloneElement } from 'react';

import s from './FooterItem.module.scss';
import { RichTextField } from '@prismicio/types';
import { RichText } from 'components/rich-text/RichText';
import { LayoutSocial_Links } from 'prismic-types';
import { toSocial } from 'prismic/prismicUtils';

type ItemProps = { title?: string; children?: React.ReactElement };

export const FooterItem = ({ title, children }: ItemProps) => {
  if (!children) {
    return null;
  }

  return (
    <div className={s.footerItem}>
      <h2 className={s.footerItem__title}>{title}</h2>
      {cloneElement(children, { className: s.footerItem__content })}
    </div>
  );
};

type ListProps = {
  title?: string;
  children?: React.ReactNode;
};

export const FooterItemList = ({ title, children }: ListProps) => {
  if (!children) {
    return null;
  }

  return (
    <div className={s.footerItem}>
      {title && <p className={s.footerItem__title}>{title}</p>}

      <ul className={s.footerItem__list}>
        {Children.map(children, (child, i) => (
          <li key={i}>{child}</li>
        ))}
      </ul>
    </div>
  );
};

type ContactProps = {
  leftTextfield: RichTextField;
  rightTextfield: RichTextField;
  socialLinks: LayoutSocial_Links[];
};

export const FooterContact = ({
  leftTextfield,
  rightTextfield,
  socialLinks,
}: ContactProps) => {
  return (
    <div className={s.footerContanct}>
      <div className={s.footerContanct__textfield}>
        {leftTextfield && <RichText>{leftTextfield}</RichText>}
      </div>

      <div className={s.footerContanct__textfield}>
        {rightTextfield && (
          <RichText className={s.footerContanct__rightTextfield}>
            {rightTextfield}
          </RichText>
        )}
        {socialLinks && toSocial(socialLinks ?? [])}
      </div>
    </div>
  );
};
