import { resolvePrismicLinkWithTitle } from 'prismic/linkResolver';
import {
  LayoutBottom,
  LayoutBottom_Certifications,
  LayoutSlicesFooterFooter_Item,
  LayoutSocial_Links,
  Scalars,
} from 'prismic-types';

import { Footer } from 'components/footer/Footer';
import {
  FooterBottom,
  FooterBottomCertifications,
  FooterBottomContainer,
  FooterBottomItem,
} from 'components/footer/FooterBottom';
import {
  FooterContact,
  FooterItem,
  FooterItemList,
} from 'components/footer/FooterItem';
import { Link } from 'components/link/Link';
import { asText, RichText } from 'components/rich-text/RichText';
import { RichTextField } from '@prismicio/types';
import { PrismicPicture } from 'components/picture/PrismicPicture';

type Props = {
  slices: Array<LayoutSlicesFooterFooter_Item | undefined>;
  bottom: Array<LayoutBottom>;
  contactTitle: Array<Scalars['Json']>;
  leftTextfield: RichTextField;
  rightTextfield: RichTextField;
  certifications: Array<LayoutBottom_Certifications>;
  socialLinks: Array<LayoutSocial_Links>;
};

export const FooterContainer = ({
  slices,
  bottom,
  contactTitle,
  leftTextfield,
  rightTextfield,
  certifications,
  socialLinks,
}: Props) => {
  return (
    <Footer
      bottom={
        <FooterBottomContainer>
          <FooterBottom>
            {bottom.map((item, i) => (
              <FooterBottomItem key={i}>
                <RichText key={i}>{item?.contact}</RichText>
              </FooterBottomItem>
            ))}
          </FooterBottom>
          <FooterBottomCertifications>
            {certifications.map((item, i) => (
              <li key={i}>
                <PrismicPicture
                  image={item.certification}
                  width={item.certification.dimensions?.width ?? 48}
                  height={item.certification.dimensions?.height ?? 48}
                />
              </li>
            ))}
          </FooterBottomCertifications>
        </FooterBottomContainer>
      }
      contact={
        <FooterItem
          title={contactTitle ? asText(contactTitle[0]?.text) : undefined}
        >
          <FooterContact
            leftTextfield={leftTextfield}
            rightTextfield={rightTextfield}
            socialLinks={socialLinks}
          />
        </FooterItem>
      }
    >
      {slices.map((item, i) => {
        if (!item) {
          return null;
        }

        switch (item.variation?.__typename) {
          case 'LayoutSlicesFooterFooter_itemWithlinks':
            return (
              <FooterItemList
                title={asText(item.variation.primary?.title)}
                key={i}
              >
                {(item.variation.items ?? []).map((link, k) => {
                  const resolved = resolvePrismicLinkWithTitle(
                    link.footerItemLink,
                    link.linkText
                  );

                  if (!resolved) {
                    return null;
                  }

                  return (
                    <Link to={resolved.link} key={k}>
                      {resolved.title}
                    </Link>
                  );
                })}
              </FooterItemList>
            );

          default:
            return null;
        }
      })}
    </Footer>
  );
};
